import React from 'react';
function EmpowerThyself() {

    // let {id} = useParams();

    // const heroImageUrl: string = "https://images.unsplash.com/photo-1490682143684-14369e18dce8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80";
    const heroImageUrl: string = "./images/artem-sapegin-hero.png";

    return (
        <>
            <section className="flex relative place-content-center items-center">
                <img src={heroImageUrl} alt="sun light" className="w-full"/>
                <div className="absolute inset-0 bg-black opacity-30"></div>
                <div className="heading-container absolute flex flex-col space-y-12 justify-center items-center">
                    <h1 className="font-bold title-font text-4xl text-white text-center">Create a Foundation of Light and Magick</h1>
                    <button className="flex-shrink-0 bg-amber-100 border-0 py-2 px-8 focus:outline-none hover:bg-amber-300 rounded text-lg mt-10 sm:mt-0">
                        I'm Ready
                    </button>
                </div>
            </section>

            {/*The Lineage of King Salomon is an unbroken and very-much-alive lineage*/}
            {/*<br/>*/}
            
            {/*<section className="text-gray-600 body-font">*/}
            {/*   */}
            {/*</section>*/}

            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <div className="text-center lg:w-2/3 w-full">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Empower Thyself</h1>
                        <p className="mb-8 leading-relaxed">
                            Initiation into the Lineage of King Salomon.
                        </p>
                        <p>
                            
                            This two-day class and initiation will give you the knowledge and tools to build a spiritual foundation in the Light.  This class includes ancient teachings, 
                            rituals, and knowledge as handed-down for over 3000 years.
                            
                            <br/>
                            You can read more <a href="https://modernmysteryschoolint.com/lineage/">here</a>
                        </p>
                        {/*<div className="mt-10 flex justify-center">*/}
                        {/*    <NavigationButton text="Sign up" isPrimary={true} location="/signup"/>*/}
                        {/*    <p className="ml-4"/>*/}
                        {/*    <NavigationButton text="Contact" location="/contact"/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </>
    );
}


export default EmpowerThyself;
