import React from "react";
import {Link} from "react-router-dom";

function Header() {
    return (<header className="text-gray-600 body-font">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
            <nav className="flex lg:w-3/5 flex-wrap items-center text-base md:ml-auto">
                {/*<Link to="/" className="mr-5 hover:text-gray-900">*/}
                {/*    Home*/}
                {/*</Link>*/}
                <Link to="/classes" className="mr-5 hover:text-gray-900">
                    Classes
                </Link>
                <Link to="/sessions" className="mr-5 hover:text-gray-900">
                    Healing Sessions
                </Link>
                {/*<Link to="/empowerthyself" className="mr-5 hover:text-gray-900">*/}
                {/*    Empower Thyself*/}
                {/*</Link>*/}
                <Link to="/calendar">
                    <a className="mr-5 hover:text-gray-900">Calendar</a>
                </Link>
            </nav>
            <div className="lg:w-1/5 inline-flex lg:justify-end ml-5 lg:ml-0">
                {/*<Link to="/contact">*/}
                {/*    <button className="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Contact*/}
                {/*        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">*/}
                {/*            <path d="M5 12h14M12 5l7 7-7 7"></path>*/}
                {/*        </svg>*/}
                {/*    </button>*/}
                {/*</Link>*/}
            </div>
            <Link to="/"
               className="flex order-first lg:order-none lg:w-1/5 title-font font-medium items-center text-gray-900 lg:items-center lg:justify-center mb-4 md:mb-0">
                {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
                {/*     className="w-10 h-10 text-white p-2 bg-indigo-500 rounded-full" viewBox="0 0 24 24">*/}
                {/*    <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>*/}
                {/*</svg>*/}
                <span className="ml-3 text-xl">toddmitchell.co</span>
            </Link>

        </div>
    </header>);
}

export default Header;
