import React from 'react';
import {Link} from "react-router-dom";
import {sessions} from "../../data/Sessions";

function SessionList() {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col">
                    <div className="h-1 bg-gray-200 rounded overflow-hidden">
                        <div className="w-24 h-full bg-green-500"></div>
                    </div>
                    <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
                        <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Healing and Activation Sessions</h1>
                        <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">
                            These sessions have been handed down for thousands of years through the Lineage of King Salomon. Each heals and activates
                            different parts of your being.</p>
                    </div>
                </div>
                <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                    {sessions.map((sesh, idx) =>
                        <Card key={idx}
                              title={sesh.title}
                              imageUrl={sesh.imageUrl}
                              href={sesh.key}
                              description={sesh.abstract}/>
                    )}
                </div>
            </div>
        </section>
    );
}

interface CardProps {
    title: string,
    imageUrl: string,
    description: string,
    href: string
}

function Card(props: CardProps) {
    return (
        <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
            <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={props.imageUrl}/>
            </div>
            <h2 className="text-xl font-medium title-font text-gray-900 mt-5">{props.title}</h2>
            <p className="text-base leading-relaxed mt-2">{props.description}</p>
            <Link to={props.href} className="text-green-500 inline-flex items-center mt-3">
                Learn More
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2"
                     viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
            </Link>
        </div>
    );
}

export default SessionList;
