import React from 'react';
import './App.css';
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import HealingSession from "./sections/healings/HealingSession";
import ClassList from "./sections/classes/ClassList";
import ClassOffering from "./sections/classes/Class";
import SessionList from "./sections/healings/SessionList";
import Header from "./Header";
import {NotFoundLayout} from "./NotFoundLayout";
import EmpowerThyself from "./sections/et/EmpowerThyself";
import {EventsCalendar} from "./sections/calendar/Calendar";
import StripePayment from "./sections/payment/StripePayment";
import Signup from "./pages/Signup";
import CreateQrCode from "./sections/qr/CreateQrCode";
import RouteQrCode from "./sections/qr/RouteQrCode";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index={true} element={<Home/>}/>
                    <Route path='sessions/*'>
                        <Route index={true} element={<SessionList/>}/>
                        <Route path=":sessionId" element={<HealingSession/>}/>
                    </Route>
                    <Route path='classes/*'>
                        <Route index={true} element={<ClassList/>}/>
                        <Route path=":id" element={<ClassOffering/>}/>
                    </Route>
                    <Route path='empowerthyself/*' element={<EmpowerThyself/>}/>
                    <Route path='calendar/*'>
                        <Route index={true} element={<EventsCalendar/>}/>
                    </Route>
                    <Route path='payment/*' element={<StripePayment/>}/>
                    <Route path='signup/*' element={<Signup />}/>

                    {/*<Route path='technology/*' element={<h1>WIP</h1>}/>*/}
                    <Route path='contact/*' element={<Contact/>}/>
                    {/*<Route path='technology/*' element={<h1>TODO</h1>}/>*/}
                </Route>
                <Route path='qr/*'>
                    <Route index={true} element={<CreateQrCode/>}/>
                    <Route path=":id" element={<RouteQrCode/>}/>
                </Route>
                <Route path="*" element={<NotFoundLayout/>}/>
            </Routes>
        </BrowserRouter>

    );
}

function Layout() {
    return (
        <div className="max-w-screen-2xl items-center">
            <Header/>
            <Outlet/>
            {/*<Footer/>*/}
        </div>
    );
}

export default App;
