export interface Classes {
    key: string,
    title: string,
    abstract: string,
    description: string,
    durationText: string;
    imageUrl: string,
    keyTakeaways: string[];
    exchange: number,
    paymentLink: string,
    location: string,
    schedule: string[],
    durationHours: number;
    isMultiDay?: boolean;
    durationDays?: number;
}

const DEFAULT_CLASS_LOCATION: string = "Berkeley Area";
export const classOfferings: Classes[] = [
    {
        key: "sanctuary-mediation",
        title: "Sanctuary Meditation",
        imageUrl: "/images/sanctuary.jpg",
        abstract: "The sanctuary meditation brings you into your personal sanctuary, a safe place where you control who and what comes in.  In here you access your higher self and seek and receive guidance for all aspects of your life.",
        description: "Imagine if you had a way to receive guidance that was pure and fully aligned with your needs.  Aligned on a level, that even if the guidance required you to face a fear or do what you normally consider ‘against yourself,’ you would feel clarity in knowing it was the right way forward.\n" +
            "<br/><br/>" +
            "This is one of the benefits of the sanctuary meditation." +
            "<br/><br/>" +
            "During this class, you receive a tested and proven technique for meditating, reaching a state of peace, and consulting with your higher self.  You’ll receive a guided meditation as well as the steps required to do this meditation at home; any time you choose.",
        exchange: 40,
        durationText: "2.5 hours",
        keyTakeaways: ["Heighten your connection with your Higher Self",
            "Receive clear and grounded guidance from your self",
            "Reach silence and chill out"
        ],
        paymentLink: "https://buy.stripe.com/dR602QbRtgUtfRe8wB",
        durationHours: 2.5,
        schedule: ["2023-08-26 1:30 pm", "2023-09-16 10:00am", "2023-10-14 10:00am", "2023-10-28 1:30 pm", "2024-04-27 6:00pm"],
        location: DEFAULT_CLASS_LOCATION
    },
    {
        key: "spiritual-intuition",
        title: "Spiritual Intuition",
        imageUrl: "/images/flower.png",
        abstract: "It's said that we have 5 senses, yet have you considered your inner senses or your inner knowing?  This class teaches you proven techniques and practices you can use daily to tune your spiritual senses.",
        description: "It's said that we have 5 senses, yet in reality we have 10 total senses, 5 external and 5 internal.  A sixth sense, clairaudience, clairvoyance, etc. are other names for your innate ability to discern" +
            " and 'know' without knowing how you know." +
            "<br/><br/>This class teaches you proven techniques and practices from the Modern Mystery School you can use daily to activate and tune your spiritual senses.",
        exchange: 40,
        durationText: "2.5 hours",
        keyTakeaways: [
            "Increase your discernment when receiving messages from source/spirit/God",
            "Boost your spiritual senses",
            "Experience the vivid nature of life"
        ],
        paymentLink: "https://buy.stripe.com/8wM7vi1cP6fP7kI6os",
        durationHours: 2.5,
        schedule: ["2023-08-26 10:00 am", "2023-09-16 1:30pm", "2023-10-28 10:00 am", "2024-04-27 3:00pm"],
        location: DEFAULT_CLASS_LOCATION
    },
    // {
    //     key: "sevenmysterschools",
    //     title: "The 7 Mystery Schools",
    //     imageUrl: "https://dummyimage.com/1204x504",
    //     abstract: "",
    //     description: "",
    //     exchange: 40,
    //     durationText: "2.5 hours",
    //     keyTakeaways: []
    // }, 
    {
        key: "astral-travel",
        title: "Astral Travel I",
        imageUrl: "/images/universe.png",
        abstract: "Learn to shift your mind and awareness to explore the Known Universe",
        description: "Astral travel is the technique of shifting your awareness to travel the known Universe.  In this class, I share a proven and safe technique to travel, explore, and boost your conscious awareness of your life and the known Universe." +
            "<br/> <br/>" +
            "Note: While it is not required, I highly recommend taking the Sacred Geometry I course as it gives you keys to experience all of Astral Travel",
        exchange: 150,
        durationText: "4 hours",
        keyTakeaways: [
            "Learn about the 7 spiritual dimensions",
            "Explore anywhere in the known universe",
        ],
        paymentLink: "https://buy.stripe.com/4gw9Dq2gTdIhdJ69AC",
        durationHours: 4,
        schedule: ["2023-08-27 3:30 pm", "2023-09-23 3:00 pm", "2023-10-08 3:00 pm", "2023-10-29 3:00 pm", "2024-04-21 3:00 pm", "2024-05-20 6:30 pm"],
        location: DEFAULT_CLASS_LOCATION
    },
    // {
    //     key: "stressrescue",
    //     title: "Stress Rescue",
    //     imageUrl: "https://dummyimage.com/1204x504",
    //     abstract: "",
    //     description: "",
    //     exchange: 40,
    //     durationText: "2.5 hours",
    //     keyTakeaways: [
    //         "Learn the inner causes of stress",
    //         "Take home easy techniques to resolve stress",
    //     ],
    //         schedule: []
    // },
    {
        key: "sacred-geometry-1",
        title: "Sacred Geometry I",
        imageUrl: "/images/sg1.jpg",
        abstract: "Sacred Geometry is often called the language of creation.  Activate your 3 keys and awaken your usage of Sacred Geometry in your daily life",
        description: "Sacred Geometry is known as the language of creation.  It is a live and accessible in all that we do and " +
            "experience - music, art, food, and movement." +
            "<br/><br/>" +
            "It shapes and structures our very lives.  It is possible to awaken and integrate " +
            "conscious use of Sacred Geometry to create higher energy and positive environments as well as Sacred temples for your spiritual practice." +
            "<br/><br/>" +
            "During this class we will awaken the 3 keys to Heaven and learn techniques to protect and heighten your home, office, and sacred spaces." +
            "<br/><br/>" +
            "Awakening these Keys expands your ability and experience while in <a href='./astral-travel'>Astral Travel</a> and <a href='./sanctuary-meditation'>Sanctuary</a> Mediations.",
        exchange: 150,
        durationText: "4 hours",
        keyTakeaways: [
            "Activate the 3 keys to Heaven within you",
            "Boost your astral travel experience",
            "Create Sacred Temples and spaces for your Spiritual Practice"
        ],
        paymentLink: "https://buy.stripe.com/cN2g1O4p10Vv48w8wz",
        durationHours: 4,
        schedule: ["2023-08-27 10:00 am", "2023-09-23 10:00 am", "2023-10-08 10:00 am", "2023-10-29 10:00 am", "2024-04-21 10:00 am"],
        location: DEFAULT_CLASS_LOCATION
    },
    {
        key: "empower-thyself",
        title: "Empower Thyself",
        imageUrl: "/images/et.jpg",
        abstract: "This two-day class hands down the fundamentals for you to create an empowered life, embracing your divinity; and initiation into the Lineage of King Salomon",
        description: "This two-day class hands down the fundamentals for you to create an empowered life, embracing your divinity; and initiation into the Lineage of King Salomon",
        exchange: 1250,
        durationText: "2 days",
        keyTakeaways: [
            "Take home meditation tools and protection rituals to keep your energy secured",
            "Learn the origin story and trajectory of Humanity with deep understanding of how we are all one",
            "Gain 10x the power and align yourself with the Will of God"
        ],
        durationHours: 8,
        isMultiDay: true,
        durationDays: 2,
        paymentLink: "https://buy.stripe.com/aEU5na08L8nXgVibIT",
        schedule: ["2023-12-09 10:00 am", "2024-09-28 10:00 am"],
        location: DEFAULT_CLASS_LOCATION
    },
    // {
    //     key: "awakenthyself",
    //     title: "Awaken Thyself",
    //     imageUrl: "https://dummyimage.com/1204x504",
    //     abstract: "",
    //     description: "",
    //     exchange: 40,
    //     durationText: "2.5 hours",
    //     keyTakeaways: [],
    //         schedule: []
    // },
]