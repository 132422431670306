import React from 'react';
import {Navigate, useParams} from "react-router-dom";
import {links} from "../../data/QrLinks";


function RouteQrCode() {

    let {id} = useParams();

    if (null == id) {
        return <Navigate to={"/"}/>
    }

    const link = links[id] ?? "";

    if (link) {
        window.location.href = link;
        return <h1 className="">loading...</h1>;
    }

    return <Navigate to={"/"}/>
}


export default RouteQrCode;
