export interface Session {
    key: string,
    title: string,
    abstract: string,
    description: string,
    durationText: string;
    imageUrl: string,
    keyTakeaways: string[];
    exchange: number,
    paymentLink: string
}

export const sessions: Session[] = [
    {
        key: "life-activation",
        title: "Life Activation",
        imageUrl: "/images/sun-trees.jpg",
        abstract: "It all starts here. The life activation reconnects you to your Divine Blueprint and expands your ability to hold more positive energy and Joy.",
        description: "The Life Activation is an ancient healing which expand your ability to hold light (positive energy). In doing so, you hold greater ability to positively influence every situation and better understand who you are. " +
            "<br/><br/>What serves you and what should be let go becomes more clear; and you will feel more courage + clarity to act on making your life that which you desire rather than falling to external influence.\n" +
            "\n" +
            "<br/> The session takes about 90 minutes and includes magnetic balancing, expansion and activation of your DNA, and a brief reading.\n" +
            "\n" +
            "<br/><br/>In addition to the above benefits, you will be ready to receive additional activations, tools, and teachings when you choose.",
        exchange: 250,
        durationText: "90 - 120 minutes",
        keyTakeaways: ["Heighten your connection with your Higher Self",
            "Awaken your Divine Blueprint",
            "Uncover hidden talents",
            "Get clear on what you want"
        ],
        paymentLink: "https://buy.stripe.com/6oEg1O8Fh8nX7kIeV0"
    },
    {
        key: "personal-energy-clearing",
        title: "Personal Energy Clearing",
        imageUrl: "/images/smiling-woman.png",
        abstract: "Cleans up toxic emotional cords. This is a short (20 minutes) healing which sets you free from another individual. This includes negative spell removal and a good spell casting.",
        description: "We are all connected. Sometimes, those connections go toxic or sour\n" +
            "<br/><br/>" +
            "The cord cutting healing is a hands-free and safe process which cuts you free from toxic connections in your life. This serves when going through a break-up (romantic or platonic), living in constant turmoil with others, or you’ve had a particularly nasty encounter with someone.\n" +
            "<br/><br/>" +
            "This session includes Cord cutting, Negative Spell Removal, Purification by Light, Good Spell Casting",
        exchange: 111,
        durationText: "about 20 minutes",
        keyTakeaways: [
            "Cut yourself free from a negative attachment",
            "Great for post-breakup freedom",
            "Combine with a Life Activation for a nice reboot"
        ],
        paymentLink: "https://buy.stripe.com/bIY3f2bRt1ZzeNa4gn"
    },
    {
        key: "tol",
        title: "Tube of Light",
        imageUrl: "/images/rainbow-waterfall.png",
        abstract: "A tube of light is placed around you and encoded with sacred geometry.  It provides a strong layer of protection and healing as you move through life.",
        description: "In the Tube of Light session we work with Archangel Michael to build a tube of light around you which is encoded with symbols  " +
            "This tube provides protection and healing while you remain with-in it.",
        exchange: 250,
        durationText: "60 minutes",
        keyTakeaways: [
            "Provides protection as you move through the world",
            "Heals what needs to be healed",
            "Deepen your connection with Archangel Michael"
        ],
        paymentLink: "https://buy.stripe.com/7sI16Ug7JdIh5cA28g"
    },
    {
        key: "fire-and-soul",
        title: "Fire and Soul Activation",
        imageUrl: "/images/bluefire.jpg",
        abstract: "Gently activates your Kundalini energy and helps re-ignite passion in your life",
        description: "The Fire and Soul activation gently awakens your root-Kundalini energy.  It's important" +
            " to stress gently as it's know doing this too quickly can disrupt life.  Our method " +
            "involves placing symbols in your energy field which results in clearing blocks to this energy flowing upwards",
        exchange: 250,
        durationText: "60 minutes",
        keyTakeaways: [
            "Gentle awakening of your Kundalini",
            "Re-ignite your passions",
            "Boost your perception and awareness"
        ],
        paymentLink: "https://buy.stripe.com/dR6bLy4p1eMl8oM4gq"
    },
    // {
    //     key: "corewill-infusion",
    //     title: "Core Will Infusion",
    //     imageUrl: "https://dummyimage.com/1204x504",
    //     abstract: "Aligns your will with the Will of God.",
    //     description: "",
    //     exchange: 250,
    //     durationText: "60 minutes",
    //     keyTakeaways: 
    //     ["Gain clarity when out of alignment with the Will of God",
    //     ""],
    //      paymentLink: "https://buy.stripe.com/cN2aHu2gT5bLawU7sB"
    // },
    // {
    //     key: "celtic-merlin",
    //     title: "Celtic Merlin Healing",
    //     imageUrl: "https://dummyimage.com/1204x504",
    //     abstract: "Shamanic healing with Merlin, Elemental Beings, and Angels",
    //     description: "",
    //     exchange: 350,
    //     durationText: "120 minutes",
    //     keyTakeaways:["Immensely refreshing session"]
    // }
]