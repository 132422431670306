import React from "react";
import {Link} from "react-router-dom";

/*
*   All knowledge is self-knowledge and thus empowerment must come from with-in you.  However, with the onslaught of external influences such as advertising, colleagues, or other sources telling you who you should be, it can be a bit difficult to hear your true inner voice.

    As an initiate in the Lineage of King Salomon, I offer ancient and proven tools to help you clear the noise and confusion; enabling a giant leap towards knowing yourself.

    This starts with Life Activation and continues as far as you desire to go.

    The Life Activation is a healing which re-connects you to your divine blueprint; granting you more clarity in your life so you can achieve more of what you want, clear old emotional patterns, and more.  From this new foundation you can uncover your latent talents and gifts to enjoy and, if you so desire – share with the world.

    We are all connected and all know in our deepest self, we want to thrive, experience, and live in joy.  It starts and continues with you, and me, and each of us deciding what to bring to our collective experience.
* */
function Home() {
    return (
        <>
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto flex flex-wrap">
                    <div className="place-content-center lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden flex">
                        <img alt="feature" className="object-cover object-center h-full w-3/4"
                             src="/images/toddbw.jpg"/>
                    </div>
                    <div className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div className="mb-10 border-gray-200 sm:border-t-0 border-t sm:mt-0 text-center sm:text-left">
                            <p className="leading-relaxed text-lg mb-4">
                                Hi and welcome! I'm Todd.

                                <br/><br/>

                                I serve as a Spiritual Guide and Healer in the Lineage of King Salomon; focused on reminding
                                individuals of our innate sovereignty and divinity so you can live life alive!
                                <br/><br/>

                                I offer ancient teachings and energy healing as handed down by the <a href="https://modernmysteryschoolint.com/" target="_blank" rel="noreferrer">Modern Mystery School</a>
                                <br/><br/>
                                
                                In the technology space, I am a co-founder of the <a href="https://homebase.id">Homebase Platform</a>, an open source sovereign-identity-data-ownership platform.

                                <br/><br/>
                                It all starts with your <a href="/sessions/life-activation">Life Activation</a>!
                            </p>
                            {/*<Link to="/contact">*/}
                            {/*    <a className="text-green-500 inline-flex items-center">Contact me*/}
                            {/*        <svg fill="none" stroke="currentColor" stroke-linecap="round"*/}
                            {/*             stroke-linejoin="round"*/}
                            {/*             stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">*/}
                            {/*            <path d="M5 12h14M12 5l7 7-7 7"></path>*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*</Link>*/}
                        </div>

                        <hr/>

                        <div className="mt-5 flex flex-col mb-10 lg:items-start items-center">
                            <div className="flex-grow">
                                <Link to="/classes">
                                    <h2 className="text-gray-900 text-2xl title-font font-medium mb-3">Classes and Meditations
                                        <span className="mt-3 text-green-500 inline-flex items-center">
                                            <svg fill="none" stroke="currentColor" strokeLinecap="round"
                                                 strokeLinejoin="round"
                                                 strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </span>
                                    </h2>
                                </Link>
                                <p className="leading-relaxed text-base">Learn proven techniques for self discovery,
                                    protection, stillness, and finding your own answers</p>
                            </div>
                        </div>
                        <div className="flex flex-col mb-10 lg:items-start items-center">
                            <div className="flex-grow">
                                <Link to="/sessions">
                                    <h2 className="text-gray-900 text-2xl title-font font-medium mb-3">Sessions
                                        <span className="mt-3 text-green-500 inline-flex items-center">
                                            <svg fill="none" stroke="currentColor" strokeLinecap="round"
                                                 strokeLinejoin="round"
                                                 strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </span>
                                    </h2>
                                </Link>
                                <p className="leading-relaxed text-base">Receive healing and activations from the
                                    Lineage of King Salomon</p>
                            </div>
                        </div>
                        {/*<div className="flex flex-col mb-10 lg:items-start items-center">*/}
                        {/*    <div className="flex-grow">*/}
                        {/*        <Link to="/contact">*/}
                        {/*            <h2 className="text-gray-900 text-2xl title-font font-medium mb-3">Contact*/}
                        {/*                <span className="mt-3 text-green-500 inline-flex items-center">*/}
                        {/*                    <svg fill="none" stroke="currentColor" strokeLinecap="round"*/}
                        {/*                         strokeLinejoin="round"*/}
                        {/*                         strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">*/}
                        {/*                        <path d="M5 12h14M12 5l7 7-7 7"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </span>*/}
                        {/*            </h2>*/}
                        {/*        </Link>*/}
                        {/*        <p className="leading-relaxed text-base">Reach out for a free consultation session (zoom or in person)</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="flex flex-col mb-10 lg:items-start items-center">*/}
                        {/*    <div className="flex-grow">*/}
                        {/*        <Link to="/technology">*/}
                        {/*            <h2 className="text-gray-900 text-2xl title-font font-medium mb-3">Technology*/}
                        {/*                <a className="mt-3 text-green-500 inline-flex items-center">*/}
                        {/*                    <svg fill="none" stroke="currentColor" stroke-linecap="round"*/}
                        {/*                         stroke-linejoin="round"*/}
                        {/*                         stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">*/}
                        {/*                        <path d="M5 12h14M12 5l7 7-7 7"></path>*/}
                        {/*                    </svg>*/}
                        {/*                </a>*/}
                        {/*            </h2>*/}
                        {/*        </Link>*/}
                        {/*        <p className="leading-relaxed text-base">Let's bring human rights to your everyday internet experience</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
            {/*<section>*/}
            {/*    <div className="container px-5 py-24 mx-auto flex flex-wrap">*/}

            {/*        <div className="md:flex-grow"><h2*/}
            {/*            className="text-2xl font-medium text-gray-900 title-font mb-2">Bitters*/}
            {/*            hashtag waistcoat fashion axe chia unicorn</h2><p className="leading-relaxed">Glossier echo park*/}
            {/*            pug, church-key sartorial biodiesel vexillologist pop-up snackwave ramps cornhole. Marfa 3 wolf*/}
            {/*            moon*/}
            {/*            party messenger bag selfies, poke vaporware kombucha lumbersexual pork belly polaroid hoodie*/}
            {/*            portland craft beer.</p><a className="text-indigo-500 inline-flex items-center mt-4">Learn More*/}
            {/*            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"*/}
            {/*                 fill="none"*/}
            {/*                 stroke-linecap="round" stroke-linejoin="round">*/}
            {/*                <path d="M5 12h14"></path>*/}
            {/*                <path d="M12 5l7 7-7 7"></path>*/}
            {/*            </svg>*/}
            {/*        </a></div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </>);
}

export default Home;