import moment from "moment";
import {classOfferings} from "./Classes";

export const CalendarDateTimeFormat: string = "YYYY-MM-DD hh:mm a";

export interface CalendarEvent {
    classKey?: string | null,
    title: string,
    start: Date,
    end: Date,
    url: string
}

const createMultiDayEvent = (title: string, datetime: string, lenDays: number, url: string): CalendarEvent => {

    return {
        classKey: null,
        start: moment(datetime, CalendarDateTimeFormat).toDate(),
        end: moment(datetime, CalendarDateTimeFormat)
            .add(lenDays - 1, "days")
            .toDate(),
        title: title,
        url: url
    };
}

const createSingleDayEvent = (title: string, datetime: string, lenHours: number, url: string): CalendarEvent => {

    let e: CalendarEvent = {
        start: moment(datetime, CalendarDateTimeFormat).toDate(),
        end: moment(datetime, CalendarDateTimeFormat)
            .add(lenHours, "hours")
            .toDate(),
        title: title,
        url: url
    };

    return e;
}

const calendarEvents: CalendarEvent[] = [
    // createMultiDayEvent("Empower Thyself", "2023-12-09 10:00 am", 2, "/empowerthyself")
];

export const getCalendarEvents = (): CalendarEvent[] => {

    let events: CalendarEvent[] = [];
    for (const key in classOfferings) {
        let c = classOfferings[key];

        if (c.schedule?.length ?? 0 > 0) {
            for (const sk in c.schedule) {
                let scheduleItem = c.schedule[sk];
                const f = c.isMultiDay ? createMultiDayEvent : createSingleDayEvent;
                const len = c.isMultiDay ? c.durationDays ?? 1 : c.durationHours;
                events.push(f(c.title, scheduleItem, len, `/classes/${c.key}`));
            }
        }
    }

    return [...events, ...calendarEvents]

}
    
