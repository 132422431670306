import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import {classOfferings} from "../../data/Classes";
import {CalendarDateTimeFormat, getCalendarEvents} from "../../data/CalendarEvents";
import moment from 'moment';
import {NonRefundableAgreement} from "../../components/NonRefundableAgreement/NonRefundableAgreement";

function Class() {

    let {id} = useParams();
    let classOffering = classOfferings.find(c => c.key.toLowerCase() == id?.toLowerCase());

    const [showAgreement, setShowAgreement] = useState<boolean>(false);

    if (null == classOffering) {
        return <h1>No class</h1>
    }

    let nextClassText: string = "";

    if (classOffering.schedule?.length > 0) {

        const now = new Date();

        const sorted = classOffering.schedule.filter(s => (moment(s, CalendarDateTimeFormat).toDate()) >= now).sort((a, b) => {
            const aa = moment(a, CalendarDateTimeFormat).toDate();
            const bb = moment(b, CalendarDateTimeFormat).toDate();
            return aa.getTime() > bb.getTime() ? 1 : -1
        });

        const nextClassDate = moment(sorted[0], CalendarDateTimeFormat);
        if(nextClassDate.isValid())
        {
            nextClassText = `${nextClassDate.format('LLLL')} (${nextClassDate.fromNow()})`;
        }
        else{
            nextClassText = "Contact me"
        }
    }

    function handleClickBookNow() {
        setShowAgreement(true);
    }

    function handleAgree() {
        window.location.href = classOffering?.paymentLink ?? ""
    }

    function handleDisagree() {
        setShowAgreement(false);
    }

    return (
        <section className="text-gray-600 body-font overflow-hidden">
            <NonRefundableAgreement onAgreed={handleAgree} onDisagreed={handleDisagree} visible={showAgreement}/>

            <div className="container px-5 py-24 mx-auto">
                <div className="lg:w-4/5 mx-auto flex flex-wrap">
                    <div className="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                        <h2 className="text-sm title-font text-gray-500 tracking-widest">Class</h2>
                        <h1 className="text-gray-900 text-3xl title-font font-medium mb-4">{classOffering.title}</h1>
                        <div className="flex mb-4">
                            <a href="" className="flex-grow text-indigo-500 border-b-2 border-indigo-500 py-2 text-lg px-1">Description</a>
                            {/*<a className="flex-grow border-b-2 border-gray-300 py-2 text-lg px-1">Reviews</a>*/}
                            {/*<a className="flex-grow border-b-2 border-gray-300 py-2 text-lg px-1">Details</a>*/}
                        </div>

                        <p className="leading-relaxed mb-4"
                           dangerouslySetInnerHTML={{__html: classOffering.description}}/>

                        <div className="m-8">
                            <ul className="list-disc">
                                {classOffering.keyTakeaways.map((bullet, idx) => <li key={idx}>{bullet}</li>)}
                            </ul>
                        </div>

                        <div className="flex border-t border-gray-200 py-2">
                            <span className="text-gray-500">Duration</span>
                            <span className="ml-auto text-gray-900">{classOffering.durationText}</span>
                        </div>

                        <div className="flex border-t border-gray-200 py-2">
                            <span className="text-gray-500">Next Class</span>
                            <span className="ml-auto text-gray-900">{nextClassText}</span>
                        </div>

                        <div className="flex border-t border-gray-200 py-2">
                            <span className="text-gray-500">Location</span>
                            <span className="ml-auto text-gray-900">{classOffering.location}</span>
                        </div>

                        <div className="flex border-t border-gray-200 py-5"/>

                        {classOffering?.paymentLink && <div className="flex">
                            <span className="title-font font-medium text-2xl text-gray-900">${classOffering.exchange}</span>
                            <button
                                onClick={handleClickBookNow}
                                className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">Book now
                            </button>
                        </div>}

                    </div>
                    <img alt="image" className="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
                         src={classOffering.imageUrl}/>
                </div>
            </div>
        </section>
    );
}


export default Class;
