import React from "react";
import Header from "./Header";
import {Link} from "react-router-dom";

export function NotFoundLayout() {
    return (
        <>
            <Header/>
            <section className="text-gray-600 body-font relative">
                <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
                    <div className="align-middle content-center lg:w-2/3 md:w-1/2 rounded-lg overflow-hidden sm:mr-10 p-10 flex-row relative">
                        <h1 className="text-4xl pb-4">Not Found</h1>
                        <p>It looks like you've come to nowhere land. Is something missing? Let me <Link to="/contact">know</Link>.</p>
                    </div>

                </div>
            </section>
        </>
    );
}