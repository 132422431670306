import React, {useState} from 'react';
import QRCode from 'react-qr-code';

function CreateQrCode() {
    
    const [url, setUrl] = useState("");

    return <div className="m-3">
        <input type="text" value={url} onChange={(e) => setUrl(e.target.value)}
               className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 
               focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>

        {url && <div className="w-32 h-32">
            <div className="mt-3">
                <QRCode
                    size={24}
                    style={{height: "auto", maxWidth: "100%", width: "100%"}}
                    value={url}
                    viewBox={`0 0 24 24`}
                />
            </div>
        </div>}
    </div>
}


export default CreateQrCode;
