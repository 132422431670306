import Modal from 'react-modal';
import * as React from "react";
import Button from "../Button/Button";

Modal.setAppElement('#root');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export function NonRefundableAgreement(props: {
    visible: boolean,
    onAgreed(): void;
    onDisagreed(): void;
}) {

    Modal.setAppElement('#root');

    function afterOpenModal() {
    }

    function closeModal() {
        props.onDisagreed();
    }

    function handleAgree() {
        props.onAgreed();
    }

    function handleDisagreeAgree() {
        props.onDisagreed();
    }

    return <Modal
        isOpen={props.visible}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Terms"
    >
        <div className="container p-5 mx-auto flex flex-wrap max-w-xs">
            <div className="flex flex-wrap -m-4 flex-grow">
                <div className="">
                    <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Terms</h2>
                    
                    <ul className="leading-relaxed text-base">
                        <li>Non-Refundable - Your payment is non-refundable</li>
                        <li>Sessions can be rescheduled</li>
                        <li>Classes are purchased for the date you've specified</li>
                        <li>Class location and other details are provided after payment</li>
                        <li>In case of an emergency, let's chat to find a solution</li>
                    </ul>

                    <p className="my-6 leading-relaxed text-base">Click "I Agree" to be redirected to Stripe to make your payment.</p>

                    {/*<p className="leading-relaxed text-base">Enter your name and email address</p>*/}
                    
                    {/*<div className="flex flex-wrap -m-2">*/}
                    {/*    <div className="p-2 w-1/2">*/}
                    {/*        <div className="relative">*/}
                    {/*            <label htmlFor="name" className="leading-7 text-sm text-gray-600">Name</label>*/}
                    {/*            <input type="text" id="name" name="name"*/}
                    {/*                   className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className="p-2 w-1/2">*/}
                    {/*        <div className="relative">*/}
                    {/*            <label htmlFor="email" className="leading-7 text-sm text-gray-600">Email</label>*/}
                    {/*            <input type="email" id="email" name="email"*/}
                    {/*                   className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="py-4">
                        <Button isPrimary={true} onClick={handleAgree}>I Agree</Button>
                    </div>
                </div>
            </div>
        </div>
    </Modal>
}