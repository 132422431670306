import React, {MouseEventHandler} from "react";
import {useNavigate} from "react-router-dom";

export default function Button(props: {
    isPrimary?: boolean,
    onClick?: () => void;
    children: any
}) {

    const primaryClasses = "inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg";
    const secondaryClasses = "inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"

    const classes = props.isPrimary ? primaryClasses : secondaryClasses;
    return (<button onClick={props.onClick} className={classes}>{props.children}</button>)

}