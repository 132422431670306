import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptions, StripePaymentElementOptions} from '@stripe/stripe-js';
import React from "react";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Na9tbDzVWZYd77knscXXeGp30wsAxKJEBRpWDZIrLcaQvxxWBjYPgTP3zbzoN0vkqSMfjPwoX3oP0U3K9qJSetX00QV0pfCY8');

export default function StripePayment() {
    const stripe = useStripe();
    const elements = useElements();

    const options: StripeElementsOptions = {
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
        // passing the client secret obtained from the server
        // clientSecret: '{{CLIENT_SECRET}}',
    };

    const paymentOptions: StripePaymentElementOptions = {
        layout: {
            type: 'tabs',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
        },
        fields: {
            billingDetails: {
                email: "auto"
            }
        },
        terms: {
            applePay: "auto"
        },
    }

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: "https://toddmitchell.co/order/123/complete",
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                <div className="text-center lg:w-2/3 w-full">
                    <Elements stripe={stripePromise} options={options}>
                        <form onSubmit={handleSubmit}>
                            <PaymentElement options={paymentOptions}/>
                            <button className="button">Submit</button>
                        </form>
                    </Elements>
                </div>
            </div>
        </section>
    );
};
