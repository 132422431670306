import {Calendar, EventProps, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {Link, useNavigate} from "react-router-dom";
import * as React from "react";
import {FC, ReactElement} from "react";
import {CalendarEvent, getCalendarEvents} from "../../data/CalendarEvents";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const EventRenderer: FC<EventProps<CalendarEvent>> = (ce: EventProps<CalendarEvent>): ReactElement => {
    return <div>{ce.event.title}</div>;
}

export const EventsCalendar = (props: any) => {
    const navigate = useNavigate();

    function handleEventSelected(e: CalendarEvent) {
        navigate(e.url);
    }

    const allEvents = getCalendarEvents();
    const now = new Date();
    const sorted = allEvents.filter(s => s.start >= now).sort((a, b) => {
        return a.start.getTime() > b.start.getTime() ? 1 : -1
    });

    const next3 = sorted.slice(0, 3);

    return (
        <>
            <section className="text-gray-600 body-font">
                <div className="container px-5 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="flex flex-col sm:flex-row mt-10">
                            <div className="sm:w-1/3 text-center">
                                <p className="leading-relaxed text-lg mt-2">Upcoming classes</p>
                            </div>
                            <div
                                className="w-2/3 sm:pl-8 sm:border-l border-gray-200 sm:border-t-0 border-t sm:mt-0 text-center sm:text-left">
                                {next3.map((evt) =>
                                    <p className="leading-relaxed whitespace-nowrap text-lg mb-2">
                                        {evt.title}
                                        <Link to={evt.url} className="text-green-500 inline-flex items-center mt-3">
                                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                                 strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </Link>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="calendar px-5 py-24 mx-auto flex flex-col">
                <Calendar
                    localizer={localizer}
                    events={allEvents}
                    startAccessor="start"
                    endAccessor="end"
                    toolbar={true}
                    onSelectEvent={handleEventSelected}
                    components={{event: EventRenderer}}
                    views={{
                        // day: true,
                        week: false,
                        month: true,
                        // year: Year
                    }}
                />
            </section>
        </>
    )
}
